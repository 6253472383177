import axios from 'axios';
// import {useState} from 'react';
import {LOGIN, REGISTER, PROFILE, LOGOUT, GET_ALL_ROLES} from "../constants/action-types";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import store from '../store';

const notifySuccess = () => {
    toast.success('Vous ête bien connectés', {position: toast.POSITION.TOP_RIGHT, autoClose: 5000})
}

const registerSuccess = () => {
    toast.success('Vous ête bien registrés', {position: toast.POSITION.TOP_RIGHT, autoClose: 5000})
}
const notifyError = () => {
    toast.error('Votre username ou mot de pass est incorrecte', {position: toast.POSITION.TOP_RIGHT, autoClose: 5000})
}

const notifyLogout = () => {
    toast.warn('Vous ête déconnectés', {position: toast.POSITION.TOP_RIGHT, autoClose: 5000})
}

// login User Action
export const loginUser = (username, password) => async dispatch => {

    //const logged = false;
    //const [logged, setLogged] = useState(true)

    {/*const object = {};
    try {
      object = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {username, password});
    } catch {
      notifyError();
    }*/
    }

    const obj = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {username, password});

    let config = {
        headers: {
            'Authorization': 'Bearer ' + obj.data.token
        }
    }

    const user = await axios.get(`${process.env.REACT_APP_API_URL}/auth/me`, config);

    localStorage.setItem('user', JSON.stringify(user.data));
    localStorage.setItem('usertoken', obj.data.token);
    localStorage.setItem('userislogged', true);

    if (obj.data.token != "") {
        notifySuccess();
        dispatch({
            type: LOGIN,
            isLogged: true,
            token: obj.data.token,
            user: user.data
        });
    }

}

// register User
export const registerUser = (firstname, lastname, username, password, roleid, store) => async dispatch => {

    let params = {
        "username": username,
        "firstName": firstname,
        "lastName": lastname,
        "password": password,
        "role": {
            "id": roleid
        }
    }
    params = store ? {...params, "store": store} : params;

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/users`, params);

    registerSuccess();


    dispatch({
        type: REGISTER
    });

}


// login User Action
export const profileUser = () => async dispatch => {

    let config = {
        headers: {
            'Authorization': 'Bearer ' + store.getState().user.token
        }
    }

    const user = await axios.get(`${process.env.REACT_APP_API_URL}/auth/me`, config);


    dispatch({
        type: PROFILE,
        user: user.data
    });


}

// logout User Action
export const logoutUser = () => async dispatch => {

    localStorage.removeItem('user');
    localStorage.setItem('userislogged', false);
    localStorage.setItem('usertoken', "");
    notifyLogout();
    dispatch({
        type: LOGOUT,
        isLogged: false,
        token: "",
        user: {}
    });
}
//get roles
export const getAllRoles = (p) => async dispatch => {

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/roles`);

    dispatch({
        type: GET_ALL_ROLES,
        roles: res.data.entities,
    });
}