import axios from 'axios';
import {GET_SLIDERS} from "../constants/action-types";

// get all SLIDERS
export const getAllSliders = () => async dispatch => {

    var sliders = [];

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/banners`);

    res.data.entities.map((e) => {
        sliders.push(`${process.env.REACT_APP_API_URL}/uploads/banners/` + e.fileName);
    })


    dispatch({
        type: GET_SLIDERS,
        slides: sliders,
    });

}