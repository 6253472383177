import {GET_SLIDERS} from "../constants/action-types";

const initialState = {
    slides: []
};

const slideReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SLIDERS:
            return {
                slides: action.slides
            };
        default:
            return state;
    }
};

export default slideReducer;