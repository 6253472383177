import {combineReducers} from 'redux';

// Import custom components
import productReducer from './products';
import cartReducer2 from './cart';
import wishlistReducer2 from './wishlist';
import compareReducer from './compare';
import filterReducer from './filter';
import modalReducer from './modal';

import slideReducer from './slideReducer';
import categoryReducer from './categoryReducer';
import brandReducer from './brandReducer';
import variantFilterReducer from './variantFilterReducer';
import productFilterReducer from './productFilterReducer';
import variantReducer from './variantReducer';
import productsReducer from './productReducer';
import userReducer from './userReducer';

import cartReducer from './cartReducer';
import wishlistReducer from './wishlistReducer';
import packReducer from "./packReducer";
import saleReducer from "./saleReducer";


const rootReducer = combineReducers({

    categories: categoryReducer,
    brands: brandReducer,
    slides: slideReducer,
    variantfilters: variantFilterReducer,
    productFilters: productFilterReducer,
    variants: variantReducer,
    products: productsReducer,
    packs: packReducer,
    sales: saleReducer,
    user: userReducer,
    userwishlist: wishlistReducer,
    usercartlist: cartReducer,

    data: productReducer,
    cartlist: cartReducer2,
    wishlist: wishlistReducer2,
    compare: compareReducer,
    filters: filterReducer,
    modal: modalReducer
});

export default rootReducer;