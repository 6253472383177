import {GET_BRANDS} from "../constants/action-types";

const initialState = {
    brands: []
};

const brandReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BRANDS:
            return {
                brands: action.brands
            };
        default:
            return state;
    }
};

export default brandReducer;