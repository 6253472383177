import React, {useEffect} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';

import LoadingOverlay from '../components/features/loading-overlay';

import {scrollTop} from '../utils';

import {connect} from 'react-redux';

import {getAllCategories} from '../actions/categoryActions';
import {getAllSliders} from '../actions/slideActions';
import {getAllVariants, getAllPacks} from '../actions/variantActions';
import {getAllProducts} from '../actions/productActions';
import {getAllBrands} from '../actions/brandActions';

import {getCartList} from '../actions/cartlistActions';
import {getWishList} from '../actions/wishlistActions';

const ElementPages = React.lazy(() => import( './elements-route.js' ));
const ProductPages = React.lazy(() => import( './products-route.js' ));
const PackPages = React.lazy(() => import( './packs-route.js' ));
const SalePages = React.lazy(() => import( './promo-route' ));
const ShopPages = React.lazy(() => import( './shop-route.js' ));
const BlogPages = React.lazy(() => import( './blogs-route.js' ));
const OtherPages = React.lazy(() => import( './others-route.js' ));
const HomePage = React.lazy(() => import( './home-route.js' ));


function AppRoot(props) {

    useEffect(() => {
        scrollTop();
        props.getAllBrands();
        props.getAllCategories();
        props.getAllSliders();
        props.getAllPacks(1);
        // props.getAllVariants(1);
        props.getAllProducts(1);
        props.getWishList();
        props.getCartList();
    }, [])

    return (
        <React.Suspense fallback={<LoadingOverlay/>}>
            <Switch>
                <Route path={`${process.env.PUBLIC_URL}/elements`} component={ElementPages}/>
                <Route path={`${process.env.PUBLIC_URL}/product`} component={ProductPages}/>
                <Route path={`${process.env.PUBLIC_URL}/packs`} component={PackPages}/>
                <Route path={`${process.env.PUBLIC_URL}/soldes`} component={SalePages}/>
                <Route path={`${process.env.PUBLIC_URL}/shop`} component={ShopPages}/>
                <Route path={`${process.env.PUBLIC_URL}/blog`} component={BlogPages}/>
                <Route path={`${process.env.PUBLIC_URL}/pages`} component={OtherPages}/>
                <Route path={`${process.env.PUBLIC_URL}/`} component={HomePage}/>
            </Switch>
        </React.Suspense>
    )
}

export const mapStateToProps = (state, props) => {
    return {
        slides: state.slides.slides ? state.slides.slides : [],
        categories: state.categories.categories ? state.categories.categories : [],
        brands: state.brands.brands ? state.brands.brands : [],
        isLogged: state.user.isLogged ? state.user.isLogged : false,
        wishlists: state.userwishlist.wishlist ? state.userwishlist.wishlist : [],
        cartlists: state.usercartlist.cartlist ? state.usercartlist.cartlist : [],
    }
}

export default withRouter(connect(mapStateToProps, {
    getAllSliders,
    getAllCategories,
    getAllPacks,
    getAllVariants,
    getAllBrands,
    getWishList,
    getCartList,
    getAllProducts
})(AppRoot));