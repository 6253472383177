import {
    GET_ALL_SALES,
    GET_SALE_DETAIL
} from "../constants/action-types";

const initialState = {
    sales: [],
    currentPage: 1,
    totalPages: 1,
    saleDetails: {},
};

const salesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_SALES:
            return {
                ...state,
                sales: action.sales,
                currentPage: action.currentPage,
                totalPages: action.totalPages
            };
        case GET_SALE_DETAIL:
            return {
                ...state,
                saleDetails: action.saleDetails
            };
        default:
            return state;
    }
};

export default salesReducer;