import axios from 'axios';
import {
    CLEAR_CART_LIST,
    SAVE_ORDER,
    GET_CART_LIST,
    REFRESH_CART_LIST,
    GET_MY_ORDERS_LIST
} from "../constants/action-types";

import store from '../store';

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const notifySaveAddress = () => {
    toast.success('Votre Nouvelle Addresse est bien enregistrée', {position: toast.POSITION.TOP_RIGHT, autoClose: 5000})
}

const notifySaveOrder = () => {
    toast.success('Votre Commande est bien ajouté', {position: toast.POSITION.TOP_RIGHT, autoClose: 5000})
}

const notifyAdd = () => {
    toast.success('Bien Ajouté au Panier', {position: toast.POSITION.TOP_RIGHT, autoClose: 5000})
}

const notifyErr = () => {
    toast.error('Il faut être connecté pour ajouter au Panier', {position: toast.POSITION.TOP_RIGHT, autoClose: 5000})
}

const notifyRemove = () => {
    toast.warn('Bien Supprimé du Panier', {position: toast.POSITION.TOP_RIGHT, autoClose: 5000})
}
// get all products in cart list
export const getCartList = () => async dispatch => {

    if (store.getState().user.isLogged) {

        const token = store.getState().user.token;

        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/cart`, config);

        dispatch({
            type: GET_CART_LIST,
            cartlist_variants: res.data.variants,
            cartlist_packs: res.data.packs,
            total: res.data.stats.total,
            sellingPrice: res.data.stats.sellingPrice,
        });
    }
}

//clear cart list when user logout
export const clearCashList = () => async dispatch => {

    dispatch({
        type: CLEAR_CART_LIST,
        cartlist_variants: [],
        cartlist_packs: [],
        total: 0,
        sellingPrice: 0
    });

}

//add variant to cart list
export const addVariantToCartlist = (id, qty, reset) => async dispatch => {

    if (!store.getState().user.isLogged) {
        notifyErr();
    } else {
        const token = store.getState().user.token;

        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }

        }

        let params = {
            qty,
            reset
        }

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/cart/variant/${id}`, params, config);

        notifyAdd();

        dispatch({
            type: REFRESH_CART_LIST,
            cartlist_variants: res.data.variants,
            cartlist_packs: res.data.packs,
            total: res.data.stats.total,
            sellingPrice: res.data.stats.sellingPrice,

        });
    }
}

//add pack to cart list
export const addPackToCartlist = (id, qty, reset) => async dispatch => {
    if (!store.getState().user.isLogged) {
        notifyErr();
    } else {
        const token = store.getState().user.token;

        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        let params = {
            qty,
            reset
        }

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/cart/pack/${id}`, params, config);

        notifyAdd();

        dispatch({
            type: REFRESH_CART_LIST,
            cartlist_variants: res.data.variants,
            cartlist_packs: res.data.packs,
            total: res.data.stats.total,
            sellingPrice: res.data.stats.sellingPrice,

        });

    }
}

//remove from cart list
export const removeVariantFromCartlist = (id) => async dispatch => {

    const token = store.getState().user.token;

    let config = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }

    const res = await axios.delete(`${process.env.REACT_APP_API_URL}/cart/variant/${id}`, config);

    notifyRemove();

    dispatch({
        type: REFRESH_CART_LIST,
        cartlist_variants: res.data.variants,
        cartlist_packs: res.data.packs,
        total: res.data.stats.total,
        sellingPrice: res.data.stats.sellingPrice,

    });

}

//remove from cart list
export const removePackFromCartlist = (id) => async dispatch => {

    const token = store.getState().user.token;

    let config = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }

    const res = await axios.delete(`${process.env.REACT_APP_API_URL}/cart/pack/${id}`, config);

    notifyRemove();

    dispatch({
        type: REFRESH_CART_LIST,
        cartlist_variants: res.data.variants,
        cartlist_packs: res.data.packs,
        total: res.data.stats.total,
        sellingPrice: res.data.stats.sellingPrice,

    });

}

// cart list checkout
export const cartListSaveOrder = (fullname, phone, email, adressl1, adressl2, note) => async dispatch => {

    if (store.getState().user.isLogged) {

        const token = store.getState().user.token;

        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        let params = {
            "line1": adressl1,
            "line2": adressl2,
            "fullName": fullname,
            "email": email,
            "phoneNumber": phone,
            "note": note
        }

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/cart/checkout`, params, config);

        console.log("Cart Save Res : ", res);

        notifySaveOrder();

        /*dispatch({
            type: GET_CART_LIST,
            cartlist_variants: res.data.variants,
            cartlist_packs: res.data.packs,
            total: res.data.stats.total,
            sellingPrice: res.data.stats.sellingPrice,
        });*/
    }
}

// cart list get orders
export const getCartListOrders = () => async dispatch => {

    if (store.getState().user.isLogged) {

        const token = store.getState().user.token;

        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        const res = await axios.get(`${process.env.REACT_APP_API_URL}/orders/my-orders`, config);

        dispatch({
            type: GET_MY_ORDERS_LIST,
            my_orders: res.data
        });
    }
}

// save order address
export const saveAddress = (fullname, phone, email, adressl1, adressl2) => async dispatch => {

    if (store.getState().user.isLogged) {

        const token = store.getState().user.token;

        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        let params = {
            "user": {
                "id": store.getState().user.user.id
            },
            "line1": adressl1,
            "line2": adressl2,
            "fullName": fullname,
            "email": email,
            "phoneNumber": phone
        }

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/addresses`, params, config);

        notifySaveAddress();

        dispatch({
            type: SAVE_ORDER,
        });
    }
}