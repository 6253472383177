import axios from 'axios';
import {
    GET_PRODUCTS_BY_CATEGORY_SLUG,
    GET_PRODUCTS_BY_BRAND_SLUG,
    GET_PRODUCT_DETAIL,
    GET_ALL_PRODUCTS
} from "../constants/action-types";


import store from '../store';
import {profileUser} from "./userActions";

// get all products
export const getAllProducts = (p) => async dispatch => {

    const productfilters = store.getState().productFilters;

    let metadata = {
        "pagination": {
            "page": p,
            "perPage": 22
        },
        "sort": {
            "field": productfilters.sortField ? productfilters.sortField : "id",
            "asc": productfilters.sortAsc ? productfilters.sortAsc : true,
        },
        "filters": {
            "search": productfilters.search ? productfilters.search : "",
        },
        "columns": {
            "*": [],
            "variants": {
                "*": [],
            }
        }
    }

    if (productfilters.category) {
        metadata.filters.categories = {"slug": productfilters.category}
    }
    if (productfilters.brand) {
        metadata.filters.brand = {"slug": productfilters.brand}
    }

    // console.log("MetaData : ", metadata);

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/products`, {params: {meta: metadata}});


    dispatch({
        type: GET_ALL_PRODUCTS,
        products: res.data.entities,
        currentPage: res.data.meta.pagination.page,
        totalPages: res.data.meta.pagination.pages
    });

}
export const getAllProductsWithFilters = (page) => async dispatch => {

    const productfilters = store.getState().productFilters;
    let metadata = {
        "pagination": {
            "page": page,
            "perPage": 22
        },
        "sort": {
            "field": productfilters.sortField ? productfilters.sortField : "id",
            "asc": productfilters.sortAsc,
        },
        "filters": {
            "search": productfilters.search ? productfilters.search : "",
        },
        "columns": {
            "*": [],
            "variants": {
                "*": []
            },
            "categories": {
                "*": []
            },
            "brand": {
                "*": []
            }
        }
    }

    if (productfilters.category) {
        metadata.filters.categories = {"slug": productfilters.category}
    }
    if (productfilters.brand) {
        metadata.filters.brand = {"slug": productfilters.brand}
    }
    if (productfilters.sortAsc) {
        metadata.sort.asc = productfilters.sortAsc
    }

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/products`, {params: {meta: metadata}});

    dispatch({
        type: GET_ALL_PRODUCTS,
        products: res.data.entities,
        currentPage: res.data.meta.pagination.page,
        totalPages: res.data.meta.pagination.pages
    });

}

export const getProductDetail = (id) => async dispatch => {

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`);

    dispatch({
        type: GET_PRODUCT_DETAIL,
        productDetail: res.data,
        firstVariant: res.data._firstVariantId,
    });
}


export const getProductsByCategoryWithSlug = (slug) => async dispatch => {

    const metadata = {
        "pagination": {
            "page": 1,
            "perPage": 1000
        },
        "sort": {
            "field": "id",
            "asc": true
        },
        "filters": {
            "search": "",
            "categories": {
                "slug": slug
            }
        },
        "columns": {
            "*": [],
            "variants": {
                "*": []
            },
            "categories": {
                "*": []
            }
        }
    }

    if (typeof slug == 'string') {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/products`, {params: {meta: metadata}});

        dispatch({
            type: GET_PRODUCTS_BY_CATEGORY_SLUG,
            productsByCategorySlug: res.data.entities,
        });
    }


}

export const getProductsByBrandWithSlug = (slug) => async dispatch => {

    const metadata = {
        "pagination": {
            "page": 1,
            "perPage": 20
        },
        "sort": {
            "field": "id",
            "asc": true
        },
        "filters": {
            "search": "",
            "brand": {
                "slug": slug
            }
        },
        "columns": {
            "*": [],
            "variants": {
                "*": []
            },
            "brand": {
                "*": []
            }
        }
    }


    if (typeof slug == 'string') {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/products`, {params: {meta: metadata}});

        dispatch({
            type: GET_PRODUCTS_BY_BRAND_SLUG,
            productsByBrandSlug: res.data.entities,
        });
    }

}
