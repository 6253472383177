import axios from 'axios';
import {
    GET_VARIANT_BY_CATEGORY_SLUG,
    GET_VARIANT_BY_BRAND_SLUG,
    GET_ALL_PACKS,
    GET_VARIANT_DETAIL,
    GET_ALL_VARIANTS
} from "../constants/action-types";


import store from '../store';

// get all variants
export const getAllVariants = (p) => async dispatch => {

    //const res = await axios.get(`${process.env.REACT_APP_API_URL}/variants`);

    // console.log(store.getState().variantfilters.search);
    // console.log(store.getState().variantfilters.category);
    // console.log(store.getState().variantfilters.brand);

    const variantfilters = store.getState().variantfilters;

    let metadata = {
        "pagination": {
            "page": p,
            "perPage": 22
        },
        "sort": {
            "field": "id",
            "asc": true
        },
        "filters": {
            "search": variantfilters.search ? variantfilters.search : "",
        },
        "columns": {
            "*": [],
            "product": {
                "*": [],
                "categories": {
                    "*": []
                },
                "brand": {
                    "*": []
                }
            }
        }
    }

    if (variantfilters.category) {
        metadata.filters.categories = {"slug": variantfilters.category}
    }
    if (variantfilters.brand) {
        metadata.filters.brand = {"slug": variantfilters.brand}
    }

    // console.log("MetaData : ", metadata);

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/variants`, {params: {meta: metadata}});


    dispatch({
        type: GET_ALL_VARIANTS,
        variants: res.data.entities,
        currentPage: res.data.meta.pagination.page,
        totalPages: res.data.meta.pagination.pages
    });

}

// get all variants
export const getAllPacks = (p) => async dispatch => {

    //const res = await axios.get(`${process.env.REACT_APP_API_URL}/variants`);

    /*let metadata = {
      "pagination": {
        "page": p,
        "perPage": 22
      },
      "sort": {
        "field": "id",
        "asc": false
      },
      "filters": {
        "search": "",
      },
      "columns": {
        "*": [],
        "product": {
          "*": [],
          "categories": {
            "*": []
          },
          "brand": {
            "*": []
          }
        }
      }
    }*/

    /*if (variantfilters.category) {
      metadata.filters.categories = { "slug": variantfilters.category }
    }
    if (variantfilters.brand) {
      metadata.filters.brand = { "slug": variantfilters.brand }
    }*/

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/packs`);

    dispatch({
        type: GET_ALL_PACKS,
        packs: res.data.entities,
        currentPage: res.data.meta.pagination.page,
        totalPages: res.data.meta.pagination.pages
    });

}

export const getAllVariantsWithFilters = (page) => async dispatch => {

    //const res = await axios.get(`${process.env.REACT_APP_API_URL}/variants`);

    // console.log(store.getState().variantfilters.search);
    // console.log(store.getState().variantfilters.category);
    // console.log(store.getState().variantfilters.brand);

    const variantfilters = store.getState().variantfilters;

    let metadata = {
        "pagination": {
            "page": page,
            "perPage": 22
        },
        "sort": {
            "field": "id",
            "asc": true
        },
        "filters": {
            "search": variantfilters.search ? variantfilters.search : "",
        },
        "columns": {
            "*": [],
            "product": {
                "*": [],
                "categories": {
                    "*": []
                },
                "brand": {
                    "*": []
                }
            }
        }
    }

    if (variantfilters.category) {
        metadata.filters.categories = {"slug": variantfilters.category}
    }
    if (variantfilters.brand) {
        metadata.filters.brand = {"slug": variantfilters.brand}
    }

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/variants`, {params: {meta: metadata}});

    dispatch({
        type: GET_ALL_VARIANTS,
        variants: res.data.entities,
        currentPage: res.data.meta.pagination.page,
        totalPages: res.data.meta.pagination.pages
    });

}

export const getVariantDetail = (id) => async dispatch => {

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/variants/${id}`);

    dispatch({
        type: GET_VARIANT_DETAIL,
        variantDetail: res.data
    });
}

export const getVariantsByCategoryWithSlug = (slug) => async dispatch => {

    const metadata = {
        "pagination": {
            "page": 1,
            "perPage": 20
        },
        "sort": {
            "field": "id",
            "asc": true
        },
        "filters": {
            "search": "",
            "categories": {
                "slug": slug
            }
        },
        "columns": {
            "*": [],
            "product": {
                "*": [],
                "categories": {
                    "*": []
                }
            }
        }
    }

    if (typeof slug == 'string') {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/variants`, {params: {meta: metadata}});

        dispatch({
            type: GET_VARIANT_BY_CATEGORY_SLUG,
            variantsByCategorySlug: res.data.entities,
        });
    }


}

export const getVariantsByBrandWithSlug = (slug) => async dispatch => {

    const metadata = {
        "pagination": {
            "page": 1,
            "perPage": 20
        },
        "sort": {
            "field": "id",
            "asc": true
        },
        "filters": {
            "search": "",
            "brand": {
                "slug": slug
            }
        },
        "columns": {
            "*": [],
            "product": {
                "*": [],
                "brand": {
                    "*": []
                }
            }
        }
    }


    if (typeof slug == 'string') {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/variants`, {params: {meta: metadata}});

        dispatch({
            type: GET_VARIANT_BY_BRAND_SLUG,
            variantsByBrandSlug: res.data.entities,
        });
    }

}
