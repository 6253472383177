import axios from 'axios';
import {GET_BRANDS} from "../constants/action-types";

// get all brands
export const getAllBrands = () => async dispatch => {

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/brands`);

    dispatch({
        type: GET_BRANDS,
        brands: res.data.entities,
    });

}