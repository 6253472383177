import {
    GET_VARIANT_BY_CATEGORY_SLUG,
    GET_VARIANT_BY_BRAND_SLUG,
    GET_VARIANT_DETAIL,
    GET_ALL_VARIANTS
} from "../constants/action-types";

const initialState = {
    variants: [],
    currentPage: 1,
    totalPages: 1,
    variantsByCategorySlug: [],
    variantsByBrandSlug: [],
};

const variantReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_VARIANTS:
            return {
                ...state,
                variants: action.variants,
                currentPage: action.currentPage,
                totalPages: action.totalPages
            };
        case GET_VARIANT_DETAIL:
            return {
                ...state,
                variantDetail: action.variantDetail
            };
        case GET_VARIANT_BY_CATEGORY_SLUG:
            return {
                ...state,
                variantsByCategorySlug: action.variantsByCategorySlug
            };
        case GET_VARIANT_BY_BRAND_SLUG:
            return {
                ...state,
                variantsByBrandSlug: action.variantsByBrandSlug
            };
        default:
            return state;
    }
};

export default variantReducer;