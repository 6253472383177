import {
    GET_ALL_PACKS,
    GET_PACK_DETAIL
} from "../constants/action-types";

const initialState = {
    packs: [],
    currentPage: 1,
    totalPages: 1,
    packDetails: {},
};

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PACKS:
            return {
                ...state,
                packs: action.packs,
                currentPage: action.currentPage,
                totalPages: action.totalPages
            };
        case GET_PACK_DETAIL:
            return {
                ...state,
                packDetails: action.packDetails
            };
        default:
            return state;
    }
};

export default productsReducer;